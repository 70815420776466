<template>
    <div>
      <!-- Content Header (Page header) -->
      <div >
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <!-- Main row -->
            <div class="row">
              <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
              <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                <div class="card">
                  <div class="card-body table-responsive table-head">
                    <div class="row bor-bot pb-2">
                      <div class="col-md-8 pl-0">
                        <h1 class="m-0 text-dark pt-2 pb-2">Settings</h1>
                      </div>
                      <div class="col-md-4">
                        <div class="text-right pb-3">
                          <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                          </ol>
                        </div>
                      </div>
                      
                    </div>
                    <form ref="form" @submit.stop.prevent="saveMessage"> 
                      <div class="row">
                       <div class="col-md-12">                       
                          <div class="row">
                            <div class="col-md-12">
                              <div class="col-md-12 mt-3 text-right">
                                <div class="d-flex">
                                  <div>
                                    <label class="mr-3">Enable Registration</label>
                                  </div>
                                  <div>
                                    <b-form-checkbox v-model="form.reg_disable_status" name="reg_disable_status" switch value="1" unchecked-value="0" size="lg"> </b-form-checkbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-5">
                              <div v-if="form.reg_disable_status == 0" class="row">
                                <div class="col-md-3">
                                  <label>Registration Disable Message</label>
                                </div>
                                <div class="col-md-9">
                                  <textarea type="text" name="reg_disable_message" v-model="form.reg_disable_message" placeholder="Enter the message" class="form-control" ></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 text-right mt-3 mb-4 mr-auto">
                              <button type="button" class="btn btn-secondary mr-3" @click="buttonCancel()">Cancel</button>
                              <button :disabled="showLoader" type="submit" class="btn btn-primary"><i class="fas" :class="[ showLoader ? 'fa-circle-notch fa-spin' : '' ]"></i>Save</button>
                            </div>
                          </div> 
                       </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </div>
            <!-- /.row (main row) -->
          </div><!-- /.container-fluid -->
        </section>
      </div>
      <!-- /.content -->
      <Footer></Footer>
    </div>
  </template>  
  <script>
  import $ from "jquery";
  import axios from 'axios';
  import CustomAlert from "../CustomAlert";
  
  export default {
    components: { CustomAlert },
    data() {
      return {
        form: {
            id: '',
            reg_disable_message: '',
            reg_disable_status: 1,

        },
        showLoader:false,
        alertMessage: null,
        displayAlert: false,
        regSetting:'',
      }
    },
    methods: {
        buttonCancel(){
            this.form.reg_disable_message = '';
        },
        saveMessage(evt) {
            evt.preventDefault();
            this.showLoader =true;
            this.handleSubmit();
        },
        handleSubmit() {
            axios.post(this.basePath + 'api/reg_message',this.form)
                .then(function (response) {
                    this.form =response.data.data;
                    this.alertMessage = response.data.message;
                    this.displayAlert = true;
                    this.showLoader = false;
                    this.table.draw();
                    this.$refs['form'].hide()
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        },
        setDangerBg(){
          setTimeout(() => {
            $('#alert_box').children('div').removeClass('alert-success');
            $('#alert_box').children('div').addClass('alert-danger');
          }, 100);
          return false;
        },
        removeDangerBg(){
          setTimeout(() => {
            $('#alert_box').children('div').removeClass('alert-danger');
            $('#alert_box').children('div').addClass('alert-success');
          }, 100);
        },
        resetAlert(){
          this.displayAlert = false;
          this.removeDangerBg();
        },
        getregSetting(){
          axios.get(this.basePath+"api/get_reg_status").then(response => {
            if(response.data.data) {
              this.form = response.data.data;
            }
            console.log("settingmmmm",response);
          })
          .catch(err => {
            console.log(err);
          })
        },
    },
    mounted(){
      this.getregSetting();    
    },
  }
  </script>